<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">Total Nilai SPP</label>
        <v-money class="w-full" :value="initData.header.nilai_total" readonly/>
      </div>
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">DPP</label>
        <v-money class="w-full" :value="initData.header.dpp" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/4 w-full">
        <label class="ml-1 text-xs">PPN Jenis</label>
        <vs-input class="w-full" :value="initData.header.ppn_jenis" readonly/>
      </div>
      <div class="vx-col sm:w-1/4 w-full">
        <label class="ml-1 text-xs">PPN</label>
        <vs-input class="w-full" :value="`${idr(initData.header.ppn_jml)} (${idr(initData.header.ppn_persen)}%)`" readonly/>
      </div>
      <div class="vx-col sm:w-1/4 w-full">
        <label class="ml-1 text-xs">PPh Jenis</label>
        <vs-input class="w-full" :value="initData.header.pph_nama" readonly/>
      </div>
      <div class="vx-col sm:w-1/4 w-full">
        <label class="ml-1 text-xs">PPh</label>
        <vs-input class="w-full" :value="`${idr(initData.header.pph_jml)} (${idr(initData.header.pph_persen)}%)`" readonly/>
      </div>
    </div>

    <!--termin-->
    <vs-table :data="initData.pembayaran" stripe class="fix-table-input-background mt-base">
      <template slot="thead">
        <vs-th class="whitespace-no-wrap">Termin</vs-th>
        <vs-th class="whitespace-no-wrap">Tgl Due</vs-th>
        <vs-th class="whitespace-no-wrap">Retensi</vs-th>
        <vs-th class="whitespace-no-wrap">Bobot</vs-th>
        <vs-th class="whitespace-no-wrap">Persen Bayar</vs-th>
        <vs-th class="whitespace-no-wrap">Jumlah</vs-th>
        <vs-th class="whitespace-no-wrap">PPN</vs-th>
        <vs-th class="whitespace-no-wrap">PPh</vs-th>
        <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
          <vs-td class="whitespace-no-wrap">{{ item.no_termin }}</vs-td>
          <vs-td class="whitespace-no-wrap">{{ item.tgl_due }}</vs-td>
          <vs-td class="whitespace-no-wrap">{{ item.is_retention ? 'YES' : '-' }}</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.bobot }}%</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.persen | idr }}%</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.jml_total | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.ppn_jml | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.pph_jml | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.keterangan }}</vs-td>
        </vs-tr>
        <vs-tr class="text-sm">
          <vs-td></vs-td>
          <vs-td></vs-td>
          <vs-td></vs-td>
          <vs-td></vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ footer.totalPersen | idr }}%</vs-td>
          <vs-td class="whitespace-no-wrap text-right"> {{ footer.totalJumlah | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap text-right"> {{ footer.totalPpn | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap text-right"> {{ footer.totalPph | idr }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full">
        <vs-button @click="prev" type="border" icon-pack="feather" icon="icon-arrow-left">Prev</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import VMoney from '@/views/components/v-money/VMoney'

export default {
  name: 'TabPembayaran',
  props: {
    initData: {
      default: null,
      type: Object
    }
  },
  components: {
    VMoney
  },
  computed: {
    footer () {
      return {
        totalPersen: _.sumBy(this.initData.pembayaran, item => item.persen || 0),
        totalPpn: _.sumBy(this.initData.pembayaran, item => item.ppn_jml || 0),
        totalPph: _.sumBy(this.initData.pembayaran, item => item.pph_jml || 0),
        totalJumlah: _.sumBy(this.initData.pembayaran, item => item.jml_total || 0)
      }
    }
  },
  methods: {
    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    },

    prev () {
      const currentActiveTab = this.$store.state.approvals.spkKontrakApproval.activeTab
      this.$store.commit('approvals/spkKontrakApproval/SET_ACTIVE_TAB', currentActiveTab - 1)
    }
  }
}
</script>
